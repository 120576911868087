import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/filete.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import finissimoscover from "../images/finissimos-cover.png" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-salmon.png" // Tell webpack this JS file uses this image
import ingrediente from "../images/ingrediente.svg" // Tell webpack this JS file uses this image
import StopMotionSalmaoCremoso from "../images/StopMotionSalmaoCremoso.mp4"


const fileteSalmao = () => (
<Layout>
   <SEO title="Peixe Fácil - Filetes de Salmão" />
   <div className="wrapper-bg-filete">
      <div className="center-col-bg">
         <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>
      </div>
   </div>
   <div id="section-1-filete">
      <div className="wrapper-container">
         {/* <div className="mobile-logo">
            <img src={logonew} alt="Logo" />
         </div> */}
         <div className="titulo absara blue-marine">
            <p>Filete de Salmão</p>
         </div>
         <div className="descricao sneak-regular opacity">
            <p> Um clássico! Sempre presente, nos momentos mais simples ou especiais. </p>
         </div>
      </div>
   </div>
   <div id="recipes-section-filete">

   <div className="wrapper-section-3">
     
     <div className="col-1">
     <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={StopMotionSalmaoCremoso} type="video/mp4" />
         </video>
</div>
       <div className="col-2">
       <div className="wrapper">
          <div className="card-recipe">
             
             <div className="top">
             <p className="title absara"> 
                Filete de Salmão cremoso no forno
                </p>
             </div>
             <div className="bottom">
                 <div className="left-col">
                    <div className="card-ingedientes">
                     <span className="titulo absara">Ingredientes</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Filete de Salmão de 300g</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">400g de batatas</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">200g de brócolos </span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 cebolas pequenas</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 dente de alho</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">5cl de azeite virgem</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">30g de farinha</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">20cl de natas</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Pimenta Q/B</span><br></br>
                    <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Salsa Q/B</span><br></br>




                    </div>
                 </div>
                 <div className="right-col">
                      <div className="card-receita">
                      <span className="titulo absara">Receita</span><br></br>
                      <span className="span-icon">1 -</span><span className="span-text">Corte as batatas e os brócolos em pedaços e coza-os separadamente em água com sal. Retire e reserve.</span><br></br>
                      <span className="span-icon">2 -</span><span className="span-text">Coloque num tacho o azeite e refogue as cebolas picadas e o alho laminado. Deixe alourar um pouco.</span><br></br>
                      <span className="span-icon">3 -</span><span className="span-text">Junte as batatas previamente esmagadas ao refogado e polvilhe com farinha</span><br></br>
                      <span className="span-icon">4 -</span><span className="span-text">Coloque as natas e os bróculos e envolva o preparado mexendo cuidadosamente</span><br></br>
                      <span className="span-icon">5 -</span><span className="span-text">Tempere e verta o preparado refratário e disponha o salmão cortado previamente em cubos</span><br></br>
                      <span className="span-icon">6 -</span><span className="span-text">Leve ao forno a 180ºC durante 15 minutos. Retire, polvilhe com salsa picada e sirva.</span><br></br>



                    </div>
                    </div>
                </div>

                <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            527
                        </div>
                        <div className="titulo sneak-regular">
                           38
                        </div>
                        <div className="titulo sneak-regular">
                           10
                        </div>
                        <div className="titulo sneak-regular">
                      27
                        </div>
                        <div className="titulo sneak-regular">
                         2
                        </div>
                        <div className="titulo sneak-regular">
                          18
                        </div>
                        <div className="titulo sneak-regular">
                         4
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
          </div>
       </div>
       </div>
      
     </div>
      <div className="wrapper-section">
      <div className="col-1-mobile">

      </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Filete de Salmão com molho de ervas
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Filete de Salmão de 300g</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Dente de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">5cl de azeite virgem</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Limas</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Ervas aromáticas (salsa, coentros, tomilho e basílico)</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Pimenta Q/B</span>
                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Coloque o filete num tabuleiro e tempere com alho picado, azeite, sal e pimenta a gosto</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Leve o tabuleiro ao forno a 180º, durante aprox. 20 minutos</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Após esse tempo, retire do forno e regue com sumo de lima e junte as ervas aromáticas picadas. Sirva em seguida</span><br></br>
                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            668
                        </div>
                        <div className="titulo sneak-regular">
                            56
                        </div>
                        <div className="titulo sneak-regular">
                           10
                        </div>
                        <div className="titulo sneak-regular">
                      12
                        </div>
                        <div className="titulo sneak-regular">
                         4
                        </div>
                        <div className="titulo sneak-regular">
                         29
                        </div>
                        <div className="titulo sneak-regular">
                         7
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-1">

</div>
      </div>

      <div className="wrapper-section-2">
      <div className="col-1-mobile">

</div>
<div className="col-2">
 
        </div>
        <div className="col-1">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Filete de Salmão com rosmaninho
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Filete de Salmão de 300g</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">5cl de azeite</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Limão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Flor de sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Ramo de rosmaninho</span><br></br>


                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Tempere o filete com flor de sal, azeite, sumo de limão e folhinhas de rosmaninho</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Aqueça uma grelha de riscas antiaderente com um fio de azeite e grelhe o filete durante 4 minutos de cada lado em temperatura forte</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Retire o filete e acompanhe com uma salada ou legumes picados</span><br></br>



                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            600
                        </div>
                        <div className="titulo sneak-regular">
                            56
                        </div>
                        <div className="titulo sneak-regular">
                           10
                        </div>
                        <div className="titulo sneak-regular">
                      1
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                        <div className="titulo sneak-regular">
                          25
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
    
      </div>


   </div>
   <div className="wrapper-3-filete">
   <div className="logo-footer">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>

      </div>
      <div className="btn-div">
         <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>
      </div>
   </div>


</Layout>
)
export default fileteSalmao